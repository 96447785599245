<template>
  <section class="page-container">
    <el-table
      :data="menus"
      style="width: 100%;margin-bottom: 20px;"
      row-key="Id"
      border
      :tree-props="{children: 'childs'}">
      <el-table-column
        prop="menuName"
        label="菜单名称">
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建日期"
        width="230">
      </el-table-column>
      <el-table-column
        label="访问权限">
        <template slot-scope="scope">
          <span v-if="scope.row.auth === 0"> 无需权限 </span>
          <span v-if="scope.row.auth === 1"> 登陆可见 </span>
          <div v-if="scope.row.auth === 2 && scope.row.memberIds != null">
            <span v-for="item in scope.row.memberIds"> {{ item.name }} </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否可用">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0"> 可用 </span>
          <span v-else> 禁用 </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="230">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="">
            修改
          </el-button>
          <el-button
            type="text"
            @click="">
            禁用
          </el-button>
          <el-button
            type="text"
            @click="">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
export default {
  data() {
    return {
      menus: [],
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      const navList = await this.axios.get('/api/articleMenu/all')
      let menus = []
      navList.forEach(function (item,i){
        let nav = new Object();
        if (item.parentId == 0){
          nav = item
          let childs = []
          navList.forEach(function (item1,i){
            if (item.Id === item1.parentId){
              childs.push(item1)
            }
          })
          nav.childs = childs
          menus.push(nav)
        }
      })
      this.menus = menus
    },
  },
}
</script>

<style scoped>

</style>
